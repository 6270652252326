import { useForm, ValidationError } from "@formspree/react";
import freeEmailDomains from "free-email-domains";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

function AuditForm() {
	const [state, handleSubmit] = useForm("mknalbrg");
	const [emailError, setEmailError] = useState(null);

	const validateEmail = (email) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (!emailRegex.test(email)) {
			return "Please provide a valid email address.";
		}
		const domain = email.split("@")[1];
		if (freeEmailDomains.includes(domain)) {
			return "Please provide a business email.";
		}
		return null;
	};

	const handleEmailChange = (event) => {
		const email = event.target.value;
		const error = validateEmail(email);
		setEmailError(error);
	};

	const handleFormSubmit = async (event) => {
		event.preventDefault();
		const emailInput = event.target.elements["_replyto"].value;
		const emailValidationError = validateEmail(emailInput);
		if (emailValidationError) {
			setEmailError(emailValidationError);
			return;
		}
		setEmailError(null);
		await handleSubmit(event);
	};

	if (state.succeeded) {
		window.location = "/thank-you";
		return null;
	}

	return (
		<Form className="w-100" onSubmit={handleFormSubmit}>
			<Form.Group className="mb-2 mb-md-4" controlId="name">
				<Form.Control
					placeholder="Name"
					className="py-3"
					name="name"
					type="text"
				/>
			</Form.Group>
			<ValidationError prefix="name" field="name" errors={state.errors} />

			<Form.Group className="mb-2 mb-md-4" controlId="email">
				<Form.Control
					placeholder="Email Address"
					className="py-3"
					name="_replyto"
					type="email"
					onChange={handleEmailChange}
				/>
				{emailError && (
					<div>
						<p style={{ fontSize: "90%" }} className="text-danger text-start">
							{emailError}
						</p>
					</div>
				)}
			</Form.Group>
			{/* Remove default ValidationError for email as we handle it */}
			{/* <ValidationError prefix="Email" field="email" errors={state.errors} /> */}

			<Form.Group className="mb-2 mb-md-4" controlId="phone">
				<Form.Control
					placeholder="Phone Number"
					className="py-3"
					name="phone"
					type="tel"
				/>
			</Form.Group>
			<ValidationError prefix="Phone" field="phone" errors={state.errors} />

			<Form.Group className="mb-2 mb-md-4" controlId="website">
				<Form.Control
					placeholder="Website"
					className="py-3"
					name="website"
					type="text"
				/>
			</Form.Group>
			<ValidationError prefix="website" field="website" errors={state.errors} />
			{/* <Form.Group className="mb-2 mb-md-4" controlId="annualTurnover">
				<Form.Control
					placeholder="Annual Turnover"
					className="py-3"
					name="annualTurnover"
					type="text"
				/>
			</Form.Group>
			<ValidationError
				prefix="annualTurnover"
				field="annualTurnover"
				errors={state.errors}
			/> */}
			{/* <Form.Group className="mb-2 mb-md-4" controlId="noOfEmployees">
				<Form.Control
					placeholder="No of Employees"
					className="py-3"
					name="noOfEmployees"
					type="text"
				/>
			</Form.Group>
			<ValidationError
				prefix="noOfEmployees"
				field="noOfEmployees"
				errors={state.errors}
			/> */}
			{/* <Form.Group
				className="mb-2 mb-md-4 text-start"
				controlId="definedMarketingBudget"
			>
				<Form.Check
					type="checkbox"
					name="definedMarketingBudget"
					label="Defined Marketing Budget"
				/>
			</Form.Group>
			<ValidationError
				prefix="definedMarketingBudget"
				field="definedMarketingBudget"
				errors={state.errors}
			/> */}
			{/* <Form.Group className="mb-2 mb-md-4" controlId="averageOrderValue">
				<Form.Control
					placeholder="Average Order Value"
					className="py-3"
					name="averageOrderValue"
					type="text"
				/>
			</Form.Group>
			<ValidationError
				prefix="averageOrderValue"
				field="averageOrderValue"
				errors={state.errors}
			/> */}

			<Form.Group
				className="mb-2 mb-md-3 w-lg-50 d-none bg-white"
				controlId="page"
			>
				<Form.Control
					className="p-4 "
					name="page"
					type="text"
					placeholder="Website Emissions Audit"
					value="Website Emissions Audit"
				/>
			</Form.Group>
			<ValidationError prefix="page" field="page" errors={state.errors} />
			<div className="text-center">
				<Button
					variant="primary"
					style={{ borderRadius: "0.35rem" }}
					className="btn western w-100 w-md-auto  white-link-yellow px-5  mt-md-0  py-2 "
					type="submit"
					id="contact-send-btn"
					disabled={state.submitting}
				>
					SEND
				</Button>
			</div>
		</Form>
	);
}

export default AuditForm;
